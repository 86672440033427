import React from "react";
import GitHubCalendar from "react-github-calendar";
import { Row } from "react-bootstrap";

function Github() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
      <h1 className="project-heading" style={{ paddingBottom: "20px" }}>
        I code almost every time, but these are the days <strong className="purple">I commit</strong>
      </h1>
      <div style={{ overflowX: "auto", maxWidth: "100%" }}>
        <GitHubCalendar
          username="Favourlisticc"
          blockSize={15}
          blockMargin={5}
          color="#c084f5"
          fontSize={16}
        />
      </div>
    </Row>
  );
}

export default Github;
